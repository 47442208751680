import { createContext, useContext, useState } from 'react';

const CmsStateContext = createContext();

export const CmsStateProvider = ({ children }) => {
    const [isSaved, setIsSaved] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const providerData = {
        isSaved,
        setIsSaved,
        isSaving,
        setIsSaving,
    };

    return <CmsStateContext.Provider value={providerData}>{children}</CmsStateContext.Provider>;
};

export const useCmsStateContext = () => {
    return useContext(CmsStateContext);
};
